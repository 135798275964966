<template>
  <div :class="{ 'c-dark-theme': false, animated: false, fadeIn: true }">
    <!-- 알림 사용자 리스트 ------------------------------------------------------>

    <CCard border-color="info">
      <CCardHeader>

        <BRow>
          <BCol>
            <BIconArchiveFill class="mr-2"/> 알림 전송 로그
          </BCol>
          <BCol class="text-right">
            <BInputGroup>
            <BFormInput size="sm" type="date" class="small"
                        v-model="toDts" @input="getList"></BFormInput>
              <BInputGroupAppend>
                <BButton variant="info" size="sm" @click="getList">
                  <BIconArrowClockwise/>
                </BButton>
              </BInputGroupAppend>
            </BInputGroup>
          </BCol>
        </BRow>
      </CCardHeader>

      <CCardBody class="p-1">
        <vue-excel-editor
          readonly
          v-model="rows"
          ref="excelGrid"
          :page="numOfRecord"
          :readonly-style="{backgroundColor:'#4F5040'}"
          :localized-label="editorLabel"
          @select="selectRecord"
          filter-row>
          <vue-excel-column field="_id"         type="string"   key-field invisible/>
          <vue-excel-column field="createdAt"   type="datetime" width="100px" label='전송일' :to-text="toShortTime" readonly/>
          <vue-excel-column field="sType"       type="map"      width="50px" label="유형" :options="{AT:'알림톡', SMS:'SMS'}"/>
          <vue-excel-column field="evtCode"     type="map"      width="75px"  label="경보명" :options="eventCodeMap"/>
          <vue-excel-column field="tid"         type="string"   width="60px"  label="탱크#" />
          <vue-excel-column field="tName"       type="string"   width="70px"  label="탱크명" />
          <vue-excel-column field="name"        type="string"   width="70px"  label="수신자" :to-text="v=>{return toNameMask(v) }"/>
          <vue-excel-column field="hpNo"        type="string"   width="110px"  label="휴대폰번호"  :to-text="v=>{return toPhoneMask(v)}" :to-value="v=>{return toPhoneVal(v)}"/>
          <vue-excel-column field="rsCode"      type="string"   width="60px" label="결과" :to-text="v=>{return v===200? '성공': v }" />
          <vue-excel-column field="message"     type="string"   width="170px"  label="전송 텍스트"/>
        </vue-excel-editor>

        <BRow class="mt-2">
          <BCol>
          </BCol>
          <BCol class="text-right" sm="4" v-if="false">
            <BButton variant="info" size="sm"
                     @click="downloadList">
              <BIconDownload/> 다운로드
            </BButton>
          </BCol>
        </BRow>
      </CCardBody>
    </CCard>

  </div>
</template>



<script>

//-------------------------------------------------------------------------------------------------
import '../../common/HelperMixin';
import {
  apiCall,
} from '@/common/utils';
import con, {ExcelEditorLabel} from "@/common/constants";
import moment from 'moment';


//----------------------------------------------------------------------------------------------------
export default {
  name: "NoticeLogList",
  components: {
  },
  props: {
    tid: { type: String, default: null },
    status: { type: Number, default: 1 },
    simple: { type: Boolean, default: false },
  },
  data () {
    return {
      fromDts: moment().format('YYYY-MM-DD'),
      toDts: moment().format('YYYY-MM-DD'),
      selectedFile: null,
      numOfRecord: 10,
      editorLabel: ExcelEditorLabel,
      pickerShow: false,
      tankTypeMap: this.$store.state.codeMaps['TANK_TYPE'],
      eventCodeMap: this.$store.state.codeMaps['EVENT'],
      isCreateMode: false,
      isUploadMode: false,
      rows: [],
      selectedRows: [],

      formShow: false,
      isSubmitting: false,
      row: null,
      statusMap: { '0':'신규', '1':'현재', '2':'이전' },
      isNoticeTest: false
    }

  },
  async created(){
    try{
      console.log("--- NoticeUserList created---------------------");

      await this.getList();
    }catch(err){
      console.log(err);
    }
  },

  computed: {},

  mounted() {
    console.log("--- NoticeUserList mounted---------------------");
    // below is not work!
  },

  methods: {

    downloadList(){
      if(this.rows.length ){
        let filename = `notice-log-${moment().format('YYMMDD')}.xlsx`;
        this.$refs["excelGrid"].exportTable('xlsx', false, filename);
      }else{
        this.alertWarn('다운로드 할 수 없습니다.');
      }

    },


    async getList(){
      try{
        console.log( "getList() --- query-string ----> ");
        this.rows = [];
        const r = await apiCall('get', `/api/notice/log?toDts=${this.toDts}`);
        if(r.code===200){
          this.rows = r.result;
          await this.toastInfo(`알림 로그 ${this.rows.length}건 조회됨`, 'info');
        }
        if( this.$refs['excelGrid'].selectedCount) this.$refs['excelGrid'].clearAllSelected();

      }catch(err){
        await this.alertDanger(err.message);
        console.log( err );
      }

    },

    async selectRecord(idx, evt){

      if(!evt){
        // this.tankFormShow = false;
        this.selectedRows = [];
        return;
      }
      console.log("------------ selectRecord ------------", idx.length);
      this.selectedRows = this.$refs['excelGrid'].getSelectedRecords();
    },


  } // end of methods
}
</script>
