<template>
  <div :class="{ 'c-dark-theme': false, animated: false, fadeIn: true }">
    <!-- 알림 사용자 리스트 ------------------------------------------------------>

    <CCard>

      <CCardBody class="p-1">
        <vue-excel-editor
          v-model="rows"
          ref="excelGrid"
          :page="numOfRecord"
          :readonly-style="{backgroundColor:'#4F5040'}"
          :localized-label="editorLabel"
          @select="selectRecord"
          @update="updateCell"
          filter-row>
          <vue-excel-column field="_id"         type="string"   key-field invisible/>
          <vue-excel-column field="name"        type="string"   width="85px"  label="수신자명" :to-text="v=>{return toNameMask(v) }" sticky/>
<!--          <vue-excel-column field="rank"        type="string"   width="50px"  label="직급" />-->
<!--          <vue-excel-column field="position"    type="string"   width="50px"  label="직책" />-->
          <vue-excel-column field="hpNo"        type="string"   width="110px"  label="휴대폰번호"
                            :to-text="v=>{return toPhoneMask(v)}" :to-value="v=>{return toPhoneVal(v)}" sticky/>
          <vue-excel-column field="enabled"     type="map"      width="50px" label="알림" :options="{true:'사용', false:'X'}"/>
          <vue-excel-column field="sType"       type="map"      width="70px" label="발송유형" :options="{AT:'알림톡', SMS:'SMS'}"/>
          <vue-excel-column field="evt1"        type="checkYN"   width="50px" label="넘침"/>
          <vue-excel-column field="evt2"        type="checkYN"   width="50px" label="부족"/>
          <vue-excel-column field="evt3"        type="checkYN"   width="50px" label="화재"/>
          <vue-excel-column field="evt4"        type="checkYN"   width="50px" label="수분"/>
          <vue-excel-column field="evt5"        type="checkYN"   width="50px" label="누유"/>
          <vue-excel-column field="evt6"        type="checkYN"   width="55px" label="넘침2"/>
          <vue-excel-column field="evt7"        type="checkYN"   width="55px" label="부족2"/>
          <vue-excel-column field="evt9"        type="checkYN"   width="50px" label="센서"/>

          <vue-excel-column field="startDt"     type="date" width="100px" label="시작일"/>
          <vue-excel-column field="endDt"       type="date" width="100px" label="종료일"/>
          <vue-excel-column field="fromTime"    type="string"   width="60px" label="시작시간" :to-value="v=>{return toHHmm(v)}"/>
          <vue-excel-column field="toTime"      type="string"   width="60px" label="종료시간" :to-value="v=>{return toHHmm(v)}"/>

          <vue-excel-column field="updatedAt"   type="datetime" width="110px" label='수정일' :to-text="toLocalTime" readonly/>
          <vue-excel-column field="updId"       type="string"   width="80px" label='수정자' readonly/>
<!--          <vue-excel-column field="createdAt"   type="datetime" width="110px" label='등록일' :to-text="toLocalTime" readonly/>-->
<!--          <vue-excel-column field="regId"       type="string"   width="80px" label='등록자' readonly/>-->
        </vue-excel-editor>

        <BRow class="mt-2">
          <BCol>
            <BButtonGroup>
            <BButton size="sm" variant="primary" class="mr-1" @click="addNoticeUser">
              <BIconNodePlusFill/> 추가
            </BButton>

              <BButton variant="info" size="sm" class="mr-3"
                       @click="noticeTest"
                       :disabled="selectedRows.length===0 || isNoticeTest">
                <BIconstack font-scale="0.98" :animation="isNoticeTest?'spin':'none'">
                  <BIconArrowClockwise/>
                </BIconstack>
                시험전송
              </BButton>



            <BButton variant="danger" size="sm"
                     @click="deleteRecord"
                     :disabled="selectedRows.length===0">
              <BIconTrashFill/> 삭제
            </BButton>

            </BButtonGroup>
          </BCol>

          <BCol class="text-right" sm="6">
            <BButtonGroup>
              <BButton variant="warning" size="sm" class="mr-2"
                       @click="clickUploadList">
                <BIconUpload/> 업로드
              </BButton>
              <BButton variant="info" size="sm" class="mr-2"
                       @click="downloadList">
                <BIconDownload/> 다운로드
              </BButton>
              <BButton variant="primary" size="sm" type="button" @click="reloadNoticeUser">
                <BIconBootstrapReboot/> 수신자 반영
              </BButton>
            </BButtonGroup>
          </BCol>
        </BRow>
      </CCardBody>
    </CCard>



    <BModal id="modal-upload"
            ref="modalUpload"
            title="알림 수신자 파일 업로드"
            header-bg-variant="primary"
            centered
            scrollable
            no-close-on-backdrop>
      <BRow>
        <BCol sm="8">
          <b-form-file
            v-model="listFile"
            :state="Boolean(listFile)"
            accept=".xls, .xlsx, .cell"
            placeholder="파일 선택 또는 드래그&드랍"
            drop-placeholder="여기에 파일을 드랍"
            @input="excelFileSelected"
          />

        </BCol>
        <BCol>
          <BButton variant='primary'
                   :disabled="!Boolean(listFile)"
                   @click="clickImportRecords">파일 업로드</BButton>
        </BCol>
      </BRow>


    </BModal>

  </div>
</template>



<script>

//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  apiCall, apiMultiPart, cloneVar
} from '@/common/utils';
import {ExcelEditorLabel} from "@/common/constants";
// import * as XLSX from 'xlsx';
import moment from 'moment';

const _notice_user = {
  name        : '수신자',
  hpNo        : '010-0000-0000',
  enabled     : true,
  rank        : '직급',
  position    : '직책',
  startDt     : null, // 시작기간
  endDt       : null, // 종료기간
  sType       : 'AT',
  fromTime    : '00:00', // HH:mm
  toTime      : '23:59', // HH:mm
  evt1        : 'Y',
  evt2        : 'Y',
  evt3        : 'Y',
  evt4        : 'Y',
  evt5        : 'Y',
  evt6        : 'Y',
  evt7        : 'Y',
  evt8        : 'N',
  evt9        : 'N',
  evt10       : 'N',
  evt11       : 'N',
  evt12       : 'N',
};

const noticeColMap = {
  name        : '수신자',
  hpNo        : '휴대폰번호',
  enabled     : '알림사용여부',
  rank        : '직급',
  position    : '직책',
  sType       : '전송유형',
  startDt     : '시작일',
  endDt       : '종료일',
  fromTime    : '시작시간',
  toTime      : '종료시간',
  repeatTime  : '반복횟수',
  evt1        : '넘침알림',
  evt2        : '부족알림',
  evt3        : '화재알림',
  evt4        : '수분알림',
  evt5        : '누유알림',
  evt6        : '넘침2',
  evt7        : '부족2',
  evt8        : '예비',
  evt9        : '센서',
}

//----------------------------------------------------------------------------------------------------
export default {
  name: "NoticeUserList",
  components: {
  },
  props: {
    tid: { type: String, default: null },
    status: { type: Number, default: 1 },
    simple: { type: Boolean, default: false },
  },
  data () {
    return {
      noticeUser : null,
      selectedFile: null,
      isUpdatingRecord: false,
      isTransRecord: false,
      isUploadingTable: false,
      numOfRecord: 10,
      editorLabel: ExcelEditorLabel,
      pickerShow: false,
      oilCodeMap: this.$store.state.codeMaps['OIL_CODE'],
      tankTypeMap: this.$store.state.codeMaps['TANK_TYPE'],
      isCreateMode: false,
      isUploadMode: false,
      rows: [],
      selectedRows: [],

      formShow: false,
      isSubmitting: false,
      row: null,
      statusMap: { '0':'신규', '1':'현재', '2':'이전' },
      isNoticeTest: false,
      listFile: null,
      isFileLoading: false,
      uploadCount: 0
    }

  },
  async created(){
    try{
      console.log("--- NoticeUserList created---------------------");

      await this.getList();
    }catch(err){
      console.log(err);
    }
  },

  computed: {},

  mounted() {
    console.log("--- NoticeUserList mounted---------------------");
    // below is not work!
  },

  methods: {

    async addNoticeUser(){
      try{

        this.noticeUser = cloneVar(_notice_user);
        this.noticeUser.name = (this.rows.length+1)+'수신자';
        this.noticeUser.startDt = moment().format('YYYY-MM-DD');
        this.noticeUser.endDt = moment().add(3,'year').endOf('day').format('YYYY-MM-DD');
        const r = await apiCall('post', `/api/notice/user`, this.noticeUser );
        console.log(r);
        if(r.code===200){
          await this.getList();
          this.noticeUser = null;
        }
        await this.toastResult(r, '수신자 추가');
      }catch(err){
        await this.alertError(err);
      }

    },

    async reloadNoticeUser(){
      try{
        const r = await apiCall('get', `/api/notice/user-reload`);
        console.log(r);
        await this.toastResult(r, '알림 수신자 정보 반영');
      }catch(err){
        await this.alertError(err);
      }
    },

    downloadList(){
      if(this.rows.length ){
        let filename = `notice-user-${moment().format('YYMMDD')}.xlsx`;
        this.$refs["excelGrid"].exportTable('xlsx', false, filename);
      }else{
        this.alertWarn('다운로드 할 수 없습니다.');
      }
    },

    clickUploadList(){
      this.listFile = null;
      this.$refs['modalUpload'].show();
    },

    async clickImportRecords() {
      if( !(await this.confirmModal('선택한 파일을 업로드 하시겠습니까?', '수신자 리스트 파일 업로드')) ){
        this.$refs['modalUpload'].hide();
        this.listFile = null;
      }else{
        await this.uploadFile();
      }
    },

    async uploadFile(){

      const formData = new FormData();
      formData.append("listFile", this.listFile);
      try {
        this.isFileLoading = true;
        const r = await apiMultiPart(`/api/notice/list-upload`, formData);

        if (r.code === 200) {
          this.listFile = null;
          await this.alertSuccess(`등록: ${r.result.inserted}건, 수정:${r.result.updated}건, 오류: ${r.result.skip}건 (${r.result.msg})`);
          await this.getList();
        }else{
          await this.alertDanger( r.message );
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.isFileLoading = false;
        this.$refs['modalUpload'].hide();
      }
    },



    excelFileSelected() {
      console.log("############## excelFileSelected #################");
      // console.log( this.file );
      if (!this.listFile) return;
      console.log(this.listFile);
    },

    async updateCell(rec){

      let rc = rec.pop();
      if(!rc.keys[0]){
        alert( "KEY_NOT_FOUND" );
        return;
      }

      console.log( "updateRecord--------- record.$id--->",rc.$id );
      console.log( "updateRecord--------- record.keys[0]--->",rc.keys[0] );

      let r = null;

      let param = {};
      const objectId = rc.keys[0];
      const fieldName = rc.name;
      param[fieldName] = rc.newVal.trim().toUpperCase();
      try{
        console.log( 'old value --->',rc.oldVal, 'new value --->', rc.newVal );
        /*
        if(fieldName==='startDt'){
          param[fieldName] =  moment( rc.newVal, 'YYYY-MM-DD 00:00:00').toISOString();
        }else if(fieldName==='endDt'){
          param[fieldName] =  moment( rc.newVal, 'YYYY-MM-DD').endOf('day').toISOString();
        }
        */
        console.log( '-----> will be updated to --->', param);
        r = await apiCall("PUT", `/api/notice/user/${objectId}`, param);
        console.log( r );
        await this.toastResult(r, `${noticeColMap[fieldName]} 수정`);
      }catch(err){
        await this.alertDanger(err.message, r.code);
        console.log( err );
      }

    },
    async getList(){
      try{
        console.log( "getList() --- query-string ----> ");
        this.rows = [];
        const r = await apiCall('get', `/api/notice/user`);
        if(r.code===200){
          this.rows = r.result;
          await this.toastInfo(`알림 수신자 ${this.rows.length}건 조회됨`, 'info');
        }
        if( this.$refs['excelGrid'].selectedCount) this.$refs['excelGrid'].clearAllSelected();

      }catch(err){
        await this.alertDanger(err.message);
        console.log( err );
      }

    },

    async selectRecord(idx, evt){

      if(!evt){
        // this.tankFormShow = false;
        this.selectedRows = [];
        return;
      }
      console.log("------------ selectRecord ------------", idx.length);
      this.selectedRows = this.$refs['excelGrid'].getSelectedRecords();
    },

    async noticeTest(){
      try{

        let params = this.selectedRows;
        this.progCount = 0;
        this.totalCount = params.length;
        if(!params.length) return this.toastInfo('[선텍된 레코드 없음] 좌측의 번호를 클릭하여 레코드 선택');

        const confirmMsg = `${params.length} 개의 레코드에 대하여 테스트 전송 합니다. 진행 하시겠습니까?`;

        if( !(await this.confirmModal(confirmMsg, '알림 전송 시험')) ){
          return;
        }
        this.isNoticeTest = true;
        let failCnt = 0, okCnt = 0;
        for( let record of params){
          this.progCount++;
          const r = await apiCall('POST', `/api/notice/test`, record );
          // console.log( r );
          if( r.code===200 ){
            if( r.result===true ) okCnt++
            else failCnt++;
          }
        }
        await this.alertModal(`총 ${this.progCount}건 / 성공: ${okCnt} / 실패: ${failCnt}`, '시험전송 결과', 'warning');
        this.isNoticeTest = false;
      }catch(err){
        this.isNoticeTest = false;
        console.error( err );
      }finally{
        this.progCount = 0;
      }

    },

    async deleteRecord(){
      // let r = null, msg = null, notice = null;
      try{

        let params = this.selectedRows;
        this.progCount = 0;
        this.totalCount = params.length;
        if(!params.length) return this.toastInfo('[선텍된 레코드 없음] 좌측의 번호를 클릭하여 레코드 선택');

        const confirmMsg = `${params.length} 개의 데이터를 삭제 합니다. 삭제된 데이터는 복구할 수 없습니다. 진행 하시겠습니까?`;

        if( !(await this.confirmModal(confirmMsg, '레코드 삭제')) ){
          return;
        }

        let failCnt = 0;
        for( let record of params){
          console.log( "deleteRecord for --------->", record );
          const tIndex = this.$refs['excelGrid'].rowIndex[record.$id];
          const r = await apiCall('DEL', `/api/notice/user/${record._id}`);
          console.log( r );
          if( r.code===200 ){
            this.progCount++;
            await this.$refs["excelGrid"].deleteRecord(tIndex);
          }
        }

        await this.alertModal(`삭제: ${this.progCount}건, 실패: ${failCnt}`, '레코드 삭제결과', 'warning');
        this.$refs['excelGrid'].clearAllSelected();
        this.selectedRows = [];
        await this.getList();

      }catch(err){

        console.log( err );
      }
    },




  } // end of methods
}
</script>
